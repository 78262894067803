import { forkJoin, of, throwError } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { serialize } from 'helpers/index.js'
import Fetch from '../fetch'
import DataHelper from 'framework/helpers/data'
import VoltError from 'VoltError'
import AuthType from 'framework/helpers/auth'
import ConfigHelper from 'framework/helpers/config'

/**
 * Deal with user preferences metadatas
 * Service provided by MarketONE. We can post and retrieve all metadatas we want
 */
export default class UserPreferencesApi extends Fetch {
    static UserId = undefined

    static USER_PREFERENCES = {
        PARENTAL_LEVEL: 'parental_level',
        FAVORITES_VODS: 'favorites_vods',
        FAVORITES_CHANNELS: 'favorites_channels',
        FAVORITES_RECORDINGS: 'favorites_recordings',
        REMINDERS: 'reminders',
        BOOKMARKS: 'bookmarks',
        SHOPPING_CART: 'shopping_cart',
    }

    static defaultConfig = {
        pageSize: 50,
    }

    supportUserPreference(userPreferences) {
        const {
            customDatas = [
                UserPreferencesApi.USER_PREFERENCES.PARENTAL_LEVEL,
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS,
            ],
        } = ConfigHelper.getInstance().getConfig('marketone')
        return customDatas.includes(userPreferences)
    }

    /**
     * This method returns the remaining profile metadatas which are not stored in the account
     * This method also create an empty user preferences if there are no metadatas (to ease the use of the API)
     * @returns {Observable<Object>}
     */
    retrieveRemainingProfile() {
        if (!this.supportUserPreference(UserPreferencesApi.USER_PREFERENCES.PARENTAL_LEVEL)) {
            return of({})
        }

        return this.getUserPreference(UserPreferencesApi.USER_PREFERENCES.PARENTAL_LEVEL).pipe(
            catchError((error) => {
                if (VoltError.codes.NOT_FOUND.code === error.code) {
                    return this.createEmptyUserPreference().pipe(
                        mergeMap(() => {
                            return of({})
                        })
                    )
                }
                const { allowProfileFetchingAtBooIfCustomDataFailure = false } =
                    ConfigHelper.getInstance().getConfig('marketone')
                if (allowProfileFetchingAtBooIfCustomDataFailure) {
                    return of({})
                }

                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_RETRIEVAL_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method returns all the user preferences
     * @warning Not performant. DO NOT use this method in production
     * @returns {Observable<Object>}
     */
    getAllUserPreferences() {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: 'GET ALL USER CUSTOM DATA',
        }).pipe(
            map(({ response }) => response),
            catchError((error) => {
                if (VoltError.codes.NOT_FOUND.code === error.code) {
                    return of({})
                }
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_RETRIEVAL_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method DELETE all the user preferences.
     * @warning You should not use this method at ALL !!! To be used only for testing purposes.
     * @returns {Observable<Boolean>}
     */
    deleteAllUserPreferences() {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}`
        return this.fetch({
            url,
            method: 'DELETE',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: 'DELETE ALL USER CUSTOM DATA',
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method creates empty user preference (Scalar value, not an array!!!)
     * @returns {Observable<Boolean>}
     */
    createEmptyUserPreference() {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[CREATE EMPTY USER CUSTOM DATA]`,
            body: {},
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * Get User Preference (only one - Scalar value Example : Parental control)
     * @param {String} userPreference Name of the user preference
     * @returns {Observable<Object>}
     */
    getUserPreference(userPreference) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }
        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}${serialize({
            include: userPreference,
        })}`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[GET USER CUSTOM DATA][${userPreference}]`,
        }).pipe(
            map(({ response }) => response),
            catchError((error) => {
                return throwError(
                    new VoltError(
                        VoltError.codes.NOT_FOUND.code === error.code
                            ? VoltError.codes.NOT_FOUND
                            : VoltError.codes.USER_PREFERENCE_RETRIEVAL_FAILED,
                        {
                            inheritedError: error,
                        }
                    )
                )
            })
        )
    }

    /**
     * This method updates ONE user preference (Scalar value, not an array!!!)
     * @param {String} userPreferenceKey User preference Key
     * @returns {Observable<Boolean>}
     */
    updateUserPreference(userPreferenceKey, userPreferenceValue) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}`
        let body = {}
        body[userPreferenceKey] = userPreferenceValue
        return this.fetch({
            url,
            method: 'PATCH',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[UPDATE USER CUSTOM DATA][${userPreferenceKey}]`,
            body: {
                [userPreferenceKey]: userPreferenceValue,
            },
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method deletes ONE user preference (Scalar value, not an array!!!)
     * @param {String} userPreferenceKey User preference Key
     * @returns {Observable<Boolean>}
     */
    deleteUserPreference(userPreferenceKey) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}${serialize({
            include: userPreferenceKey,
        })}`
        return this.fetch({
            url,
            method: 'DELETE',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[DELETE USER CUSTOM DATA][${userPreferenceKey}]`,
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * Get User Preferences (Array: Example : Bookmarks list)
     * @param {String} userPreference Name of the user preferences
     * @param {String} [filter] Optionnaly filter to apply to retrieve a subset of the user preferences
     * Example :
     * filter=id eq "ID784475"
     * filter=program_type eq "DVR"
     * @returns {Observable<Object>}
     */
    getUserPreferencesList(userPreference, filter) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const offset = 0
        const { pageSize = 50 } = UserPreferencesApi.defaultConfig

        return this.recursiveListFetch(
            (_, pageSize, offset) => {
                return this.fetch({
                    url: `${
                        this.config.urls.userPreferencesUrl
                    }/v1/user/${this.getUserId()}/${userPreference}${serialize({
                        page: offset / pageSize,
                        size: pageSize,
                        filter: filter,
                    })}`,
                    method: 'GET',
                    headers: {
                        Authorization: AuthType.BearerToken(
                            DataHelper.getInstance().getPrimaryAccessToken()
                        ),
                    },
                    log: `[GET USER CUSTOM DATA][OFFSET=${offset}][PAGE_SIZE=${pageSize}][FILTER=${
                        filter || ''
                    }]`,
                })
            },
            {
                pageSize,
                offset,
            }
        ).pipe(
            catchError((error) => {
                if (VoltError.codes.NOT_FOUND.code === error.code) {
                    return of([])
                }
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_RETRIEVAL_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method add a new User preferences to be inserted in the object Array (To be used for Object Update)
     * @param {String} userPreferences User preference key
     * @param {Object} userPreferencesValue User preferences object to be inserted in the list
     * @returns {Observable<Boolean>}
     */
    addUserPreferencesItemInList(userPreferences, userPreferencesValue) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${
            this.config.urls.userPreferencesUrl
        }/v1/user/${this.getUserId()}/${userPreferences}`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[ADD USER CUSTOM DATA][${userPreferences}]`,
            body: {
                ...userPreferencesValue,
            },
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * (Nested method) This method patch existing user prefrences (to be used)
     * @param {String} userPreferences User preference key
     * @param {Object} userPreferencesValue User preferences object to be inserted in the list
     * @param {String} filter filter to apply to retrieve a subset of the user preferences
     * Example :
     * filter=id eq "ID784475"
     * filter=program_type eq "DVR"
     * @returns {Observable<Boolean>}
     */
    _updateExistingUserPreferencesItemInList(userPreferences, userPreferencesValue, filter) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        if (!filter) {
            return throwError(
                new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                    extraLog: 'Cannot update user preference without filter parameter',
                })
            )
        }

        const url = `${
            this.config.urls.userPreferencesUrl
        }/v1/user/${this.getUserId()}/${userPreferences}${
            filter
                ? serialize({
                      filter: filter,
                  })
                : ''
        }`
        return this.fetch({
            url,
            method: 'PATCH',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[PATCH USER CUSTOM DATA][${userPreferences}][FILTER=${filter || ''}]`,
            body: {
                ...userPreferencesValue,
            },
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method updates a new User preferences to be inserted in the object Array (To be used for Object Update)
     * @param {String} userPreferences User preference key
     * @param {Object} userPreferencesValue User preferences object to be inserted in the list
     * @returns {Observable<Boolean>}
     */
    updateUserPreferencesItemInList(userPreferences, userPreferencesValue, filter) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        return this.getUserPreferencesList(userPreferences, filter).pipe(
            mergeMap((response) => {
                const isPreferenceAlreadyAvailable = !!response && response.length > 0
                if (isPreferenceAlreadyAvailable) {
                    // If existing user preferences, update it to avoid duplicate
                    return this._updateExistingUserPreferencesItemInList(
                        userPreferences,
                        userPreferencesValue,
                        filter
                    )
                }
                // Otherwise if the preferences is missing, append a new one
                return this.addUserPreferencesItemInList(userPreferences, userPreferencesValue)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * (Nested method) This method remove one user prefrences within an existing list
     * @param {String} userPreferences User preference key
     * @param {String} filter filter to apply to retrieve a subset of the user preferences
     * Example :
     * filter=id eq "ID784475"
     * filter=program_type eq "DVR"
     * @returns {Observable<Boolean>}
     */
    deleteUserPreferencesItemFromList(userPreferences, filter) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        if (!filter) {
            return throwError(
                new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                    extraLog: 'Cannot delete user preference without filter parameter',
                })
            )
        }

        const url = `${
            this.config.urls.userPreferencesUrl
        }/v1/user/${this.getUserId()}/${userPreferences}${serialize({
            filter: filter,
        })}`
        return this.fetch({
            url,
            method: 'DELETE',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[DELETE USER CUSTOM DATA][${userPreferences}][FILTER=${filter || ''}]`,
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * This method adds one user prefrences in the custom data of user
     * @param {String} userPreferences User preference key
     * @returns {Observable<Boolean>}
     */
    addUserPreference(userPreferences, userPreferencesValue) {
        if (!this.hasUserId()) {
            return throwError(new VoltError(VoltError.codes.MISSING_USER_ID))
        }

        const url = `${this.config.urls.userPreferencesUrl}/v1/user/${this.getUserId()}`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[ADD USER CUSTOM DATA][${userPreferences}]`,
            body: {
                ...userPreferencesValue,
            },
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    deleteUserPreferenceAndRefresh = (userPreference) =>
        this.deleteUserPreference(userPreference).pipe(
            mergeMap(() => this.getUserPreference(userPreference))
        )

    /**
     * Save user id to be able to use this API
     * @param {Number} userId User ID
     */
    setUserId(userId) {
        UserPreferencesApi.userId = userId
    }

    /**
     * Get user ID
     */
    getUserId() {
        return UserPreferencesApi.userId
    }

    /**
     * Check if a user id exist
     * @returns user Id
     */
    hasUserId() {
        return !!UserPreferencesApi.userId
    }
    /**
     *
     * @param {string} payload
     */
    getAllTransactionHistory(nexPageUrl) {
        const userAccountId = DataHelper.getInstance().getAccountId()
        const limit = this.config.transactionLimit
        if (!userAccountId) {
            return throwError(new VoltError(VoltError.codes.MISSING_ACCOUNT_ID))
        }
        const url =
            this.config.urls.storefrontUrl +
            (nexPageUrl ||
                (limit
                    ? `/v2/transactions_history/?accountId=${userAccountId}&order=desc&limit=${limit}`
                    : `/v2/transactions_history/?accountId=${userAccountId}&order=desc`))
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[Fetch User Trasactions History][${userAccountId}]`,
        }).pipe(
            mergeMap((transactions) => {
                // call refunds api for all ransaction item which has refunded entry
                let refundTransactions = transactions?.response?.data?.filter((item) =>
                    item.status_log?.some((i) => i.status === 'Refunded')
                )

                if (!refundTransactions?.length) {
                    return of(transactions)
                }

                let refundApiArray = refundTransactions?.map((item) =>
                    this._getRefundTransactions(item.transactionId)
                )
                return forkJoin(refundApiArray).pipe(
                    mergeMap((refundResponseArray) => {
                        refundResponseArray?.map((refundResponse) => {
                            // filter out only completed refund records and store in redux
                            let completedRefunds = refundResponse?.response.filter(
                                (i) => i.status === 'Complete'
                            )
                            let transactionId = refundResponse?.response?.[0]?.transaction?.id
                            if (transactionId) {
                                // Add refund records details in the refunded transaction
                                let transaction = refundTransactions?.find(
                                    (item) => item.transactionId === transactionId
                                )
                                if (transaction) {
                                    transaction.refundRecords = completedRefunds
                                }
                            }
                        })
                        return of(transactions)
                    })
                )
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    getAllTransactionsByAccountOrSubscriptionId({ params }) {
        let queryParam = ''
        Object.keys(params)?.map((key) => {
            queryParam += `${key}=${params[key]}&`
        })
        const url =
            this.config.urls.extensionApiEndPoint +
            `/dcm/storefront/v1/transactions_history?${queryParam}order=desc&limit=100`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[Fetch User Trasactions History][]`,
        }).pipe(
            mergeMap((transactions) => {
                // call refunds api for all ransaction item which has refunded entry
                let refundTransactions = transactions?.response?.data?.filter((item) =>
                    item?.status_log?.data?.some((i) => i.status === 'Refunded')
                )

                if (!refundTransactions?.length) {
                    return of(transactions)
                }

                let refundApiArray = refundTransactions?.map((item) =>
                    this._getRefund(params?.accountId, item?.id)
                )

                return forkJoin(refundApiArray).pipe(
                    mergeMap((refundResponseArray) => {
                        refundResponseArray?.map((refundResponse) => {
                            // filter out only completed refund records and store in redux
                            let refunds = refundResponse?.response?.data?.filter(
                                (i) => i.status === 'Complete' || i.status === 'Processing'
                            )
                            let transactionId = refundResponse?.response?.data?.[0]?.transaction?.id
                            if (transactionId) {
                                // Add refund records details in the refunded transaction
                                let transaction = refundTransactions?.find(
                                    (item) => item.id === transactionId
                                )
                                if (transaction) {
                                    transaction.refundRecords = refunds
                                }
                            }
                        })
                        return of(transactions)
                    }),
                    catchError((error) => {
                        return of(transactions)
                    })
                )
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    getUserRefundCount(accountId) {
        const url =
            this.config.urls.extensionApiEndPoint + `/dcm/storefront/v1/refundcount/${accountId}`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[Fetch User Refund Count]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return of(null)
            })
        )
    }

    /**
     *
     * @param {string} payload
     */
    _getRefund(accountId, id) {
        const url =
            this.config.urls.extensionApiEndPoint +
            `/dcm/storefront/v1/account/${accountId}/transactions/${id}/refunds`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[Fetch Refund Trasactions][${id}]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return of(null)
            })
        )
    }

    /**
     *
     * @param {string} payload
     */
    _getRefundTransactions(id) {
        const userAccountId = DataHelper.getInstance().getAccountId()
        if (!userAccountId) {
            return throwError(new VoltError(VoltError.codes.MISSING_ACCOUNT_ID))
        }
        const url = this.config.urls.storefrontUrl + `/transactions/${id}/refunds`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[Fetch Refund Trasactions][${id}]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    updateAuthIdentifierStartProcess = () => {
        const url = `${this.config.urls.apiUrl}/process/start/userManagement.AddOrUpdateAuthnIdentifier.v1.0`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[UPDATE AUTH IDENTIFIER PROCESS START]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.UPDATE_AUTH_IDENTIFIER_START_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    updateAuthIdentifierStep = (processDetails) => {
        const url = `${this.config.urls.apiUrl}/process/step`
        return this.fetch({
            url,
            method: 'PUT',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[UPDATE AUTH IDENTIFIER STEP]`,
            body: { ...processDetails },
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.EMAIL_NOT_VERIFIED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    updateAuthIdentifierValidateOTP = (otp, pkat) => {
        const url = `${this.config.urls.apiUrl}/session/token?customToken=${otp}&pkat=${pkat}`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[UPDATE AUTH IDENTIFIER OTP VALIDATION]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.UPDATE_AUTH_IDENTIFIER_OTP_VALIDATION_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    updateAuthIdentifierResendOTP = (pkat) => {
        const url = `${this.config.urls.apiUrl}/session/token?pkat=${pkat}`
        return this.fetch({
            url,
            method: 'PUT',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[UPDATE AUTH IDENTIFIER RESEND OTP]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.UPDATE_AUTH_IDENTIFIER_RESEND_OTP_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    updateAuthAccountAddress = (addressDetails) => {
        const accountId = DataHelper.getInstance().getAccountId()
        const url = `${this.config.urls.storefrontUrl}/account/${accountId}/update`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[UPDATE AUTH ACCOUNT ADDRESS]`,
            body: { ...addressDetails },
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.UPDATE_AUTH_ADDRESS_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    verifyAccountAddress = (addressDetails) => {
        const url = `${this.config.urls.ifsApiUrl}/geo-address-management/v1/address-validation?addressType=shipping&maximumAlternativeAddresses=1`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[ADDRESS VERIFICATION]`,
            body: { ...addressDetails },
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.ADDRESS_VERIFICATION_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }
}
