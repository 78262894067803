import { M1UserSubscription } from '../models/M1UserSubscription'
import { M1PartnerSubscription } from '../models/M1PartnerSubscription'
import { Subscription } from 'models/Subscription'
import ConfigHelper from 'framework/helpers/config'
import Constants from 'api-constants'
import { isEmpty } from 'lodash'

const redemptionCodePattern = '{{redemptionCode}}'
const ONE_YEAR_MS = 31536000000 // = (1 * 365 * 24 * 60 * 60 * 1000) (To save CPU hihi :) )

/**
 * Parse Market One User Entitlements/Subscription
 * @param {M1UserSubscription} M1UserSubscriptions Market One subscription
 */
export const M1UserSubscriptionFactory = (data = {}) => {
    const {
        id,
        status = 'activated',
        type,
        attributes: { paymentProviderSubscriptions = [] } = {},
    } = data

    return new M1UserSubscription({
        status,
        type,
        transactionId: id,
        ...(paymentProviderSubscriptions.length > 0 && paymentProviderSubscriptions[0]),
    })
}

/**
 * Parse Market One Partner Subscriptions
 * A Partner Subscription is an external Service which can require ACTIVATION after a transaction
 * @param {M1PartnerSubscription} Partner Subscription
 */
export const M1PartnerSubscriptionFactory = (data = {}) => {
    return new M1PartnerSubscription({
        ...data,
    })
}

/**
 * Quick Helper which convert Partner Subscription status to Activation status
 * @param {String} serviceStatus from M1PartnerSubscription
 * @returns {String} Subscription.ACTIVATION_STATUS
 */
export const convertActivationStatus = (serviceStatus) => {
    switch (serviceStatus) {
        // Amdocs has confirmed that INACTIVE means need to be activated
        case M1PartnerSubscription.SERVICE_STATUS.INACTIVE:
            return Subscription.ACTIVATION_STATUS.ACTIVATION_REQUIRED

        case M1PartnerSubscription.SERVICE_STATUS.ACTIVE:
            return Subscription.ACTIVATION_STATUS.ACTIVATED

        case M1PartnerSubscription.SERVICE_STATUS.CANCELLED:
        case M1PartnerSubscription.SERVICE_STATUS.CANCELLATION_IN_PROGRESS:
            return Subscription.ACTIVATION_STATUS.CANCELLED

        case M1PartnerSubscription.SERVICE_STATUS.UNPROVISIONED:
            return Subscription.ACTIVATION_STATUS.WAITING_ACTIVATION_LINK

        default:
            return Subscription.ACTIVATION_STATUS.ACTIVATION_NOT_REQUIRED
    }
}

/**
 * Quick Helper which convert Partner Subscription status to Activation status
 * @param {Array<Subscription>} subscription subscription coming from the PROXY
 * @param {Array<M1PartnerSubscription>} partnerSubscription Partner subscription (Specific to MarketONE)
 * @returns {String} Subscription.ACTIVATION_STATUS
 */
export const convertActivationUri = (activationUri, partnerSubscription = {}) => {
    // But for devices (STB) which cannot launch a browser, use a deeplink
    // This is based on a work around solution provided by Market One
    // Therefore we need to replace '{{redemptionCode}}'
    // Ex: com.netflix.ninja/{{redemptionCode}}/activate
    return partnerSubscription.registrationString && activationUri
        ? activationUri.replace(redemptionCodePattern, partnerSubscription.registrationString)
        : activationUri
}

/**
 * This method consolidates the list of subscriptions coming from the IFS PROXY by enriching it with
 * Entitlements attributes : transactionId, entitlementStart, entitlementEnd, isEntitled, isAvailable
 * @param {Array<Subscription>} listSubscriptions List of subscriptions coming from the PROXY
 * @param {Array<M1UserSubscription>} M1UserSubscriptions List of Entitlements coming from Market One
 * @param {Array<M1PartnerSubscription>} M1PartnerSubscriptions List Partner. To know if the package required activation before to use it
 * otherwise it would be through email or browser
 */
export const updateSubscriptionsEntitlements = (
    listSubscriptions = [],
    M1UserSubscriptions = [],
    M1PartnerSubscriptions = [],
    inactiveSubscriptions = []
) => {
    let isSupportingAndroidStbActivation = false
    const { platform } = ConfigHelper.getInstance().getConfig()
    switch (platform) {
        case Constants.platform.androidTvStb:
        case Constants.platform.androidTv:
        case Constants.platform.amazonTv:
            isSupportingAndroidStbActivation = true
            break
        default:
            break
    }

    const disablePackageEntitlementEndDate =
        ConfigHelper.getInstance().getConfig().disablePackageEntitlementEndDate

    /**
     * To reduce computation complexity, create an intermediary object
     * {"productId" : { userSubscription: obj, partnerSubscription: obj }}
     */
    let M1Subs = M1UserSubscriptions.reduce((acc, userSubscription) => {
        const productId = userSubscription && userSubscription.productId
        if (productId) {
            const partnerSub = M1PartnerSubscriptions.find(
                (elt) => elt && elt.isLinkedWithUserSubscription(userSubscription)
            )

            // There is an issue in Amdocs backend, there could be a multiple entitlements
            // Workaround take the most recent one
            const entitlements = acc[productId]
            if (
                entitlements &&
                userSubscription &&
                entitlements.userSubscription &&
                entitlements.userSubscription.subscriptionEndDate >
                    userSubscription.subscriptionEndDate /*&&
                userSubscription.status === userSubscription.isEntitled()*/
            ) {
                // Do nothing
            } else {
                acc[productId] = {
                    userSubscription: userSubscription,
                    partnerSubscription: partnerSub,
                }
            }
        }
        return acc
    }, {})
    const checkStatus = (targetProductId) => {
        return (
            inactiveSubscriptions?.some(
                (subscription) => subscription.productId === targetProductId
            ) || false
        )
    }

    const _mapProductsKeyswithSubscriptions = (
        product,
        userSubscription,
        partnerSubscription,
        isSubscription = false
    ) => {
        let activationUri = partnerSubscription.partnerUrl // Default value
        let redemptionCodeExpirationTime = partnerSubscription?.redemptionCodeExpirationTime
        let partnerTokenizedUrl = partnerSubscription?.partnerTokenizedUrl
        const isUsingAndroidDeeplinkActivation = !!product.activationUri
        if (isUsingAndroidDeeplinkActivation) {
            activationUri = convertActivationUri(product.activationUri, partnerSubscription)
        }
        let webActivationUri = partnerSubscription.partnerUrl // Default value
        const isUsingWeblinkActivation = !!product.webActivationUri
        if (isUsingWeblinkActivation) {
            webActivationUri = convertActivationUri(product.webActivationUri, partnerSubscription)
        }

        let activationMode = isSupportingAndroidStbActivation
            ? isUsingAndroidDeeplinkActivation
                ? Subscription.ACTIVATION_MODE.DEEPLINK
                : Subscription.ACTIVATION_MODE.EMAIL
            : isUsingWeblinkActivation
            ? Subscription.ACTIVATION_MODE.DEEPLINK
            : Subscription.ACTIVATION_MODE.EMAIL
        let obj = {
            transactionId: userSubscription.transactionId,
            transactionAlternativeId: userSubscription.transactionAlternativeId,
            entitlementStart: userSubscription.subscriptionStartDate,
            // MarketONE backend is inconsistent yet to provide the real entitlement end date (There is a CR planned on their side after big negotiation)
            // To keep the consistency with VOLT framework which relies on this date to make the product purchase, we rely on the activate status,
            // this is why we define arbitrary a constant entitlement duration of one year pending the fix from MarketONE
            entitlementEnd: disablePackageEntitlementEndDate
                ? Date.now() + ONE_YEAR_MS
                : userSubscription.subscriptionEndDate,
            isEntitled: userSubscription.isEntitled(),
            billingDate: userSubscription.nextSubscriptionBillingDate,
            isAvailable: true,
            activationStatus: Subscription.ACTIVATION_STATUS.ACTIVATION_NOT_REQUIRED,
            activationUri,
            webActivationUri,
            activationCode: partnerSubscription.registrationString,
            activationId: partnerSubscription.partnerSubscriptionId,
            redemptionCodeExpirationTime,
            partnerTokenizedUrl,
            activationMode,
            impactedSubscriptionItems: userSubscription.impactedSubscriptionItems,
            hasPreviousRecord: checkStatus(userSubscription.id),
        }

        // Add activation and subscription status only if product is subscribed
        if (isSubscription) {
            const activationStatus = convertActivationStatus(partnerSubscription.serviceStatus)
            obj = {
                ...obj,
                subscriptionStatus: userSubscription.subscriptionStatus,
                activationStatus: activationStatus,
            }
        }
        return obj
    }

    /**
     * Then enrich entitlements and activation parameters from Subscriptions coming from the proxy
     */
    return (listSubscriptions || []).reduce((acc, subscription) => {
        if (subscription) {
            if (subscription.id && M1Subs && M1Subs[subscription.id]) {
                const { userSubscription = {}, partnerSubscription = {} } = M1Subs[subscription.id]
                if (userSubscription.isEntitled && userSubscription.isEntitled()) {
                    acc.push(
                        subscription.update(
                            _mapProductsKeyswithSubscriptions(
                                subscription,
                                userSubscription,
                                partnerSubscription,
                                true
                            )
                        )
                    )
                    return acc
                }
            }

            // Following section is added for hard bundle to match with user and partner subscription
            let partnerSubscription =
                M1PartnerSubscriptions.find((i) => i.slmProductCode === subscription.id) || {}
            if (!isEmpty(partnerSubscription)) {
                let productIds = listSubscriptions
                    .filter((p) => {
                        return p.children.find((o) => o.platform_id === subscription.id)
                    })
                    .map((o) => o.id)
                let userSubscription = M1UserSubscriptions.find((k) =>
                    productIds.includes(k.productId)
                )
                if (subscription.id && !isEmpty(userSubscription)) {
                    acc.push(
                        subscription.update(
                            _mapProductsKeyswithSubscriptions(
                                subscription,
                                userSubscription,
                                partnerSubscription,
                                false
                            )
                        )
                    )
                    return acc
                }
            }

            acc.push(
                subscription.update({
                    isEntitled: false,
                    isAvailable: true,
                    activationStatus: Subscription.ACTIVATION_STATUS.ACTIVATION_NOT_REQUIRED,
                    hasPreviousRecord: checkStatus(subscription.id),
                })
            )
        }

        return acc
    }, [])
}
