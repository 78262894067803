import { EmiRequest, RequestFilter } from 'models'
import Constants from 'api-constants'

import { deduplicateEmiRequests, resolveEmiRequestId, adaptPredefinedPrequest } from '../helpers'

/**
 * Returns an instance of EmiRequest which represent :
 * - A prerequest (ie: data retrieval should be done on consumer project)
 *      - id = the request name
 *      - value = the request data name or the request id (as a fallback)
 *      - args = an empty array (no arguments required)
 * - A list of ids (ie: metada must be retrieved from consumer project, from the list of ids)
 *      - id = the request name
 *      - value = `Constants.customRequests.GET_CONTENTS_FROM_IDS` constant
 *      - args = an array of item description which each contains id, thumbnail (if overriden),
 *               backdrop (if overriden), provider (always undefined during my investigations) and
 *                type (ex: 'app', 'vod')
 *
 * Note: provider = the request provider (ex: 'VODContent', 'Suggestion', anything use fill in
 * "Data provider" field of Content Selector item)
 *
 * @param {Object} request
 * @param {Object} appConfig configuration, provided by the Consumer Project
 * @return {Models.EmiRequest}
 */
export const emiRequestFactory = (request, appConfig, applications = [], allFilters) => {
    const matchingRequest = Object.values(allFilters).find(
        (obj) => obj?.request && obj?.request?.name === request?.name
    )
    const bannerSize = matchingRequest?.hero_size
    const blockSize = matchingRequest?.zone_size
    const predefinedRequest = (request.items || []).find((item) => item.type === 'collection')
    const { supportedLayoutsFromEMi = [] } = appConfig || {}

    let useLayoutFromEMI

    if (predefinedRequest) {
        //// Preparsing
        const _predefinedRequest = adaptPredefinedPrequest(predefinedRequest)
        const { filter, sort, limit, ...otherData } = _predefinedRequest.data || {}

        if (_predefinedRequest.order_by) {
            otherData.order_by = predefinedRequest.order_by
        }

        if (_predefinedRequest.dataset) {
            otherData.dataset = predefinedRequest.dataset
        }

        useLayoutFromEMI = supportedLayoutsFromEMi.includes('predefined')
        const value = deduplicateEmiRequests(
            [
                _predefinedRequest.provider,
                (_predefinedRequest.data && _predefinedRequest.data.name) || _predefinedRequest.id,
            ]
                .filter(Boolean)
                .join('_')
        )

        const _request = new EmiRequest({
            value,
            args: Object.keys(otherData).length ? [otherData] : [],
            requestFilter: new RequestFilter({ filter, sort, limit }),
            layout: useLayoutFromEMI ? _predefinedRequest.layout : undefined,
            softBundles: predefinedRequest?.soft_bundles ?? [],
            softBundlesName: predefinedRequest?.soft_bundles_name ?? '',
            category2: predefinedRequest?.category2 ?? '',
            subtype: predefinedRequest?.subtype ?? '',
            softBundleCampaignName: predefinedRequest?.name ?? '',
            softBundlesAlwaysDisplay: predefinedRequest?.soft_bundles_always_display ?? false,
        })

        return _request.update({
            id: resolveEmiRequestId(_request),
        })
    } else {
        // external_vod is an alias of VOD, not necessary to duplicate it in upper layers (see comment above)
        const items = (request.items || []).map((item) => {
            if (item.type === 'external_vod') {
                item.type = 'vod'
            }
            return item
        })
        let layout = undefined
        // As explained above, EMI is not consistent providing layout for all kind of content, use layout only for VOD and App
        // Better to do it in the factory instead of upper layers

        useLayoutFromEMI = items.some((item) => supportedLayoutsFromEMi.includes(item.type))

        if (useLayoutFromEMI) {
            const haveSameLayouts = items.every((item) => item.layout === items[0].layout)
            layout = haveSameLayouts ? items[0].layout : EmiRequest.LAYOUT.PORTRAIT
        }

        const isSubscriptionLine = (request.items || []).every((item) => item?.type === 'offer')

        return new EmiRequest({
            value: isSubscriptionLine
                ? Constants.customRequests.GET_SUBSCRIPTIONS_FROM_IDS
                : Constants.customRequests.GET_CONTENTS_FROM_IDS,
            id: request.name,
            layout,
            args: items
                .filter((item) => {
                    let currentApp
                    if (item.type === 'app' || item.type === 'application') {
                        currentApp = applications.find((app) => app.id.toString() === item.id)
                    }

                    return (
                        !!appConfig.showApps ||
                        (item.type !== 'app' && item.type !== 'application') || // both type can exist in EMI json file
                        (currentApp && currentApp.isInternalLink()) // internal links(which are of type 'app') can be added even if the client config disabled apps
                    )
                })
                .map((item) => {
                    return {
                        id: (item.data && item.data.name) || item.id,
                        thumbnail:
                            (item.media && item.media.thumbnail && item.media.thumbnail[0]) ||
                            (item.media && item.media.thumbnails && item.media.thumbnails[0]) ||
                            undefined,
                        portraitThumbnail:
                            item.media &&
                            item.media.thumbnails_portrait &&
                            item.media.thumbnails_portrait[0],
                        backdrop:
                            (item.media &&
                                item.media?.backdrop_landscape &&
                                item.media?.backdrop_landscape[0]) ||
                            (item.media && item.media.barkers && item.media.barkers[0]) ||
                            undefined,
                        provider: item.provider,
                        type: item.type,
                        dataset: item.dataset,
                        layout: useLayoutFromEMI ? item.layout : undefined,
                        bannerSize: bannerSize,
                        blockSize: blockSize,
                        title: item?.title,
                        description: item?.description,
                        buttonText: item?.button,
                        backgroundColor: item?.background_color,
                        textColor: item?.text_color,
                        interactiveBackgroundColor: item?.interactive_background_color,
                        interactiveTextColor: item?.interactive_text_color,
                        horizontalAlignment: item?.horizontal_alignment,
                        verticalAlignment: item?.vertical_alignment,
                        pricePrefix: item?.price_prefix,
                        startingPrice: item?.text_starting_price,
                        legalText: item?.text_legal_text,
                        legalUrl: item?.text_legal_url,
                        learnMoreText: item?.learn_more_text,
                        learnMoreUrl: item?.learn_more_url,
                        isLegalText: item?.is_legal_text,
                        isLegalUrl: item?.is_legal_url,
                        isLearnMoreText: item?.is_learn_more_text,
                        isLearnMoreUrl: item?.is_learn_more_url,
                        textSubscribedActive: item?.text_subscribed_active,
                        textSubscribedInactive: item?.text_subscribed_inactive,
                        textUnsubscribed: item?.text_unsubscribed,
                        isActionButtonOn: item?.is_action_button,
                        isDescription: item?.is_description,
                        isStartingPrice: item?.is_starting_price,
                        isThumbnail: item?.is_thumbnail,
                        isTitle: item?.is_title,
                        isEyeBrow: item?.is_eyebrow,
                        eyebrowText: item?.text_eyebrow,
                        useVideo: item?.use_video,
                        video: (item.media && item.media.video && item.media.video[0]) || undefined,
                        backdropLandscape:
                            (item.media &&
                                item.media?.backdrop_landscape &&
                                item.media?.backdrop_landscape[0]) ||
                            undefined,
                        backdropPortrait:
                            (item.media &&
                                item.media?.backdrop_portrait &&
                                item.media?.backdrop_portrait[0]) ||
                            undefined,
                        catalogOffers: item?.catalog_offers,
                        externalLink: item?.url,
                        pageId: item?.page_id,
                        productPlatformEmiId: `${item?.id}_${request?.name}`,
                    }
                }),
        })
    }
}
