import {useContext} from 'solid-js'
import {i18nProviderContext} from './i18nProviderContext'
import {defineMessages} from '@formatjs/intl'
import {locales} from 'client-translations'
import {useLocale} from './useLocale'
import {useConfig} from '../components'
import {isEmpty} from 'lodash'
import {dateTypes} from '../../../config'

export const useI18n = () => {
    const context = useContext(i18nProviderContext)
    const locale = useLocale()
    const {getConfig} = useConfig()
    const utcOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC',
    }
    const currencies = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        JPY: '¥',
        AUD: '$',
        CAD: '$',
    }

    return {
        defineMessages: (messages: any) => defineMessages(messages),

        formatMessage: (id: any, values?: Record<string, any>) => context?.formatMessage(id, values),

        formatCurrency: (value: number, options?: any) => {
            options.currency = options?.currency || getConfig().defaultCurrency
            const formatted: any = context?.formatNumber(value || 0, {style: 'currency', ...options})
            if (options?.isCurrencySymbolSuperscript) {
                let currencySymbol = currencies?.[options.currency]
                if (formatted.includes(currencySymbol)) {
                    const amountPart = `${formatted.split(currencySymbol)?.[0]}${formatted.split(currencySymbol)?.[1]}`
                    return (
                        <>
                            <sup style={{'font-size': options?.superFontSize, 'vertical-align': 'text-top'}}>
                                {currencySymbol}
                            </sup>
                            {amountPart}
                        </>
                    )
                }
            }
            return formatted
        },

        formatNumber: (value: number, options?: any) => context?.formatNumber(value, options),

        formatString: (stringKey: string) => {
            return locales[locale()]?.messages?.[stringKey]
        },

        formatStringWithArgs: (stringKey: string, args: Record<string, any>) => {
            const msg = locales[locale()]?.messages?.[stringKey]
            return msg.replace(/\$\{(.*?)\}/g, (_, key) => args[key] || '')
        },

        formatNotificationStringWithArgs: (stringKey: string, args: Record<string, any>) => {
            const notifications = locales[locale()]?.messages?.notification
            const msg = notifications?.[stringKey]
            return msg.replace(/\$\{(.*?)\}/g, (_, key) => args[key] || '')
        },
        formatDateInWord: (
            dateString: string,
            isPrevious: boolean = false,
            dateFormat: string = 'MMM DD, YYYY, HH:mm A nonUtc'
        ) => {
            var date = new Date(dateString)
            if (isPrevious) {
                const d = date.getMinutes() - 1
                date.setMinutes(d)
            }
            if (dateFormat === 'MMM DD, YYYY') {
                return `${date.toDateString().split(' ').slice(1).join(' ')}`
            }
            return `${date.toLocaleDateString('en-US', dateTypes[dateFormat])} (UTC)`
        },
        formatDate: (dateString: string, options: any = {}, defaultLocal: string = 'en-US'): any => {
            const date = new Date(dateString)
            if (isEmpty(options)) {
                return `${date.toLocaleDateString(defaultLocal, utcOptions)} (UTC)`
            }
            return `${date.toLocaleDateString(defaultLocal, options)}`
        },
        formatDateLongType: (dateString: string): string => {
            const monthLong = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            const [dateStr, timeStr] = dateString.replace(/\+.*/, '').split('T')
            const [year, month, day] = dateStr.split('-')
            const formattedDate = `${monthLong[parseInt(month) - 1]} ${day}, ${year}`
            const hours = parseInt(timeStr.split(':')[0])
            const hour12 = hours % 12 == 0 ? 12 : hours % 12
            const formattedHr = hour12 < 10 ? `0${hour12}` : hour12
            const minutes = timeStr.split(':')[1]
            const formattedTime = `${formattedHr}:${minutes} ${hours < 12 ? 'AM' : 'PM'}(UTC)`
            return `${formattedDate} ${formattedTime}`
        },

        isPastDate: oldDate => {
            return new Date(oldDate).getTime() < Date.now()
        },

        getUTCDate: () => {
            const date = new Date()
            return date.toUTCString()
        },
    }
}
