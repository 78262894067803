import VoltError from 'VoltError'
import { ajax } from 'rxjs/ajax'
import { map, catchError, timeoutWith, expand, mergeMap, reduce } from 'rxjs/operators'
import { throwError, EMPTY, of } from 'rxjs'
import MockLogger from 'MockLogger'
import Constants from 'api-constants'

/**
 * Fetching API class.
 */
export default class Fetch {
    constructor(config, otherApis) {
        this.config = config
        this.logger = (config.logger || MockLogger).createChildInstance('marketoneapi')
        const { authApi, metaApi, userPreferencesApi, carePortalApi } = otherApis || {}
        this.authApi = authApi
        this.metaApi = metaApi
        this.userPreferencesApi = userPreferencesApi
        this.carePortalApi = carePortalApi
    }

    /**
     * Default HTTP Settings
     */
    static defaultHttpSettings = {
        METHOD: 'GET',
        HEADERS: {
            'Content-Type': 'application/json',
        },
        pageSize: 50,
    }

    /**
     * Fetching Method
     * @param {String} url
     * @param {Object} body : HTTP URL
     * @param {Object} headers : HTTP Header
     * @param {String} method : GET, PUT, POST, DELETE, HEAD, OPTIONS
     * @param {Number} timeout
     * @param {String} log Extra message for Debug
     * @returns { response, totalCount }
     */
    fetch({
        url,
        body,
        headers = Fetch.defaultHttpSettings.HEADERS,
        method = Fetch.defaultHttpSettings.METHOD,
        timeout,
        log = '',
        redirectOnSessionExpire = true,
    }) {
        this.logger.debug(`[${log}] HttpRequest [REQUEST]: ${url}`, { method, headers, body })

        return ajax({
            url: this._parseUrl(url),
            body,
            headers,
            method,
            redirectOnSessionExpire,
        }).pipe(
            timeoutWith(
                timeout ||
                    (this.config && this.config.timeout && this.config.timeout.platform) ||
                    Constants.fallbackTimeout.platform,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            map((ajaxResponse) => {
                const response = ajaxResponse.response
                const totalCount = this._getTotalCountResponseHeader(ajaxResponse)
                this.logger.trace(`[${log}] HttpRequest [RESPONSE]`, response)
                return { response, totalCount, xhr: ajaxResponse.xhr }
            }),
            catchError((errorObject) => {
                const error = this._parseError(errorObject.response || { error: errorObject.code })
                const acr_value = this._getACRValueResponseHeader(errorObject)
                const otpToken = url.includes('customToken=') ?? false

                if (acr_value !== '') {
                    error.setReAuthenticate(true)
                    error.setAcrValue(acr_value)
                } else if (
                    acr_value === '' &&
                    errorObject.status === 401 &&
                    redirectOnSessionExpire &&
                    !otpToken
                ) {
                    window.location.assign(
                        `${window.location.origin}/root-discovery?error=sessionExpired`
                    )
                }

                this.logger.error(`[${log}] HttpRequest [ERROR]:`, {
                    url: this._filterUrlParams(url),
                    method,
                    error,
                })
                return throwError(error)
            })
        )
    }

    /**
     * Recursively executes an api call until all results have been retrieved
     *
     * @param {function} req The function called to fetch elements from the backend
     * @param {Object} opts
     * @param {number} opts.pageSize The number of elements to be retrieved per fetch
     * @param {number} opts.offset The offset of the first element of next backend call
     *
     * @returns {Array<object>} An array of raw elements
     */
    recursiveListFetch(req, opts = {}) {
        const { offset: initialOffset = 0, pageSize = Fetch.defaultHttpSettings.pageSize } = opts

        let offset = initialOffset

        return req(null, pageSize, offset).pipe(
            expand(({ response, totalCount }) => {
                offset += pageSize

                const continueParsing = totalCount && offset < totalCount
                return continueParsing
                    ? req(response, pageSize, offset)
                    : of(response).pipe(mergeMap(() => EMPTY))
            }),
            reduce((acc, { response }) => {
                response && acc.push(...response)
                return acc
            }, [])
        )
    }

    /**
     * Return Total Count of the request
     * @param {*} response including xhr object
     * @returns Total Count or undefined
     */
    _getTotalCountResponseHeader = (response) => {
        const totalCount =
            response && response.xhr && response.xhr.getResponseHeader('X-Total-Count')
        return totalCount && parseInt(totalCount)
    }

    //This is just added for quick check acr_value need to extracted correctly
    _getACRValueResponseHeader = (response) => {
        const acrValue =
            response && response.xhr && response.xhr.getResponseHeader('Www-Authenticate')

        const acrMatch = acrValue?.length > 0 ? acrValue.match(/acr_values="([^"]*)"/) : false

        if (acrMatch) {
            const acrValue = acrMatch?.[1]
            return acrValue
        }

        return ''
    }

    /**
     * Parsing Error
     * @param {Object} response
     * @returns {VoltError}
     */
    _parseError = (response) => {
        const { error, description = '' } = this._extractError(response) || {}

        if (!error) return new VoltError(VoltError.codes.UNKNOWN_API_ERROR)

        let voltError
        switch (error) {
            case 'REQUEST_TIMEOUT':
                voltError = new VoltError(VoltError.codes.REQUEST_TIMEOUT)
                break
            case 'authentication-required':
                voltError = new VoltError(VoltError.codes.AUTHENTICATION_REQUIRED, {
                    extraLog: `platform error: ${error}`,
                })
                break
            case 'INVALID_REQUEST':
            case 'invalid-request':
                voltError = new VoltError(
                    (() => {
                        if (
                            description.includes(
                                'The transaction represented by this Purchase Token is no longer valid'
                            ) ||
                            description.includes('All purchases for the provided sku have expired')
                        ) {
                            return VoltError.codes.PURCHASE_FAILED_DUE_TO_OUTDATED_IAP
                        } else if (description.includes('Initial Transaction failed')) {
                            return VoltError.codes.PURCHASE_FAILED_DUE_INVALID_PARAMETERS
                        } else if (description.includes('No purchases match the provided sku')) {
                            return VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_SKU
                        } else if (description.includes('iap-receipt-missing-orderId')) {
                            return VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_ORDER_ID
                        } else if (
                            description.includes('Subscription create failed: The currency field')
                        ) {
                            return VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_CURRENCY
                        } else if (
                            description.match(
                                /Failed to load object account: 'Error in Account->payment_methods->\[\d\]: Referenced PaymentMethod \(id=.*\) is owned by different Account/
                            )
                        ) {
                            return VoltError.codes.PURCHASE_METHOD_ALREADY_OWNED
                        } else if (
                            description.includes('Subscription create failed: Campaign code') &&
                            description.includes('is not valid')
                        ) {
                            return VoltError.codes.COUPON_VALIDATION_FAILED_COUPON_IS_NOT_VALID
                        } else if (
                            description.includes('Subscription create failed: Campaign code') &&
                            description.includes('Coupon has been used already')
                        ) {
                            return VoltError.codes.COUPON_VALIDATION_FAILED_COUPON_ALREADY_USED
                        } else if (
                            description.includes('Specified product ineligible for campaign code')
                        ) {
                            return VoltError.codes.COUPON_VALIDATION_ERROR_INELIGIBLE_PRODUCT
                        }
                        return {
                            ...VoltError.codes.SERVICE_NOT_ALLOWED,
                            code: error || VoltError.codes.SERVICE_NOT_ALLOWED,
                            description: description,
                        }
                    })(),
                    {
                        extraLog: `platform error: ${error} : ${description}`,
                    }
                )
                break
            case 'forbidden':
                voltError = new VoltError(VoltError.codes.SERVICE_NOT_ALLOWED, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'not-found':
                voltError = new VoltError(VoltError.codes.NOT_FOUND, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'invalid-credential':
                voltError = new VoltError(VoltError.codes.INVALID_PASSWORD, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'NotWeakPassword':
                voltError = new VoltError(VoltError.codes.AUTH_PASSWORD_COMPLEXITY_NOT_MET, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'input-conflict':
            case 'not-permitted':
                voltError = new VoltError(VoltError.codes.OPERATION_ALREADY_DONE, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'pin-unset':
                voltError = new VoltError(VoltError.codes.USER_PIN_UNSET, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'missing-iap-receipt':
                voltError = new VoltError(VoltError.codes.MISSING_IAP_RECEIPT, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'account-not-found':
                voltError = new VoltError(VoltError.codes.MISSING_ACCOUNT_ID, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'account-terminated':
                voltError = new VoltError(VoltError.codes.USER_ACCOUNT_TERMINATED, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'json conversion failed':
                voltError = new VoltError(VoltError.codes.UNHANDLED_API_RESPONSE_STRUCTURE, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            case 'error-comm-csp-bss':
                voltError = new VoltError(VoltError.codes.EXTERNAL_BSS_CANNOT_BE_REACHED, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
            default:
                voltError = new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                    extraLog: `platform error: ${error} : ${description}`,
                })
                break
        }

        return voltError
    }

    /**
     * Parses several form of error messaging and return an object which contains the error and the description
     * @param {Object} response
     * @returns {Object} '{ error: 'CODE', description: 'DESCRIPTION' }'
     */
    _extractError(response) {
        if (!response) {
            return { error: 'unknown', description: 'unknown' }
        }
        if (response.operationError) {
            return {
                error: response.operationError.code,
                description: response.operationError.message,
            }
        }
        if (response.code) {
            return {
                error: response.code,
                description: response.message,
            }
        }
        if (response.error) {
            return {
                error: response.error,
                description: response.error_description,
            }
        }
        if (
            response.fieldErrors &&
            Array.isArray(response.fieldErrors) &&
            response.fieldErrors.length > 0
        ) {
            return {
                error: response.fieldErrors[0].code,
                description: response.fieldErrors[0].message,
            }
        }

        return { error: 'unknown', description: 'unknown' }
    }

    _parseUrl(url) {
        // IMPORTANT : Backend fall in error if we provide a double slash
        return url && url.replace(/([^:]\/)\/+/g, '$1')
    }

    _filterUrlParams(url) {
        // IMPORTANT : To filter for GDPR
        return url && url.split('?')[0]
    }
}
